import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';
import logo from '../assets/images/logo.png';
import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';

const IndexPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: 'home_page_title' })} />
    <section id="banner">
      <div className="inner">
        <h2 className="title-ziben">
          <img src={logo} alt="" className="logo-ziben" />
        </h2>
        <p>{intl.formatMessage({ id: 'description' })}</p>
        <ul className="actions special">
          <li>
            <OutboundLink
              href={intl.formatMessage({ id: 'form_url' })}
              target="_blank"
              rel="noreferrer"
              className="button primary"
            >
              {intl.formatMessage({ id: 'hire_button' })}
            </OutboundLink>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          {intl.formatMessage({ id: 'read_more' })}
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>{intl.formatMessage({ id: 'introduction_title' })}</h2>
          <p>
            {intl.formatMessage({ id: 'introduction_description_1' })} <br />
            {intl.formatMessage({ id: 'introduction_description_2' })}
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon fa-flask solid major style1">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon fa-gem major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon solid fa-arrows-alt major style3">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="" />
        </div>
        <div className="content">
          <h2>{intl.formatMessage({ id: 'mission_title' })}</h2>
          <p>{intl.formatMessage({ id: 'mission_description' })}</p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="" />
        </div>
        <div className="content">
          <h2>{intl.formatMessage({ id: 'vision_title' })}</h2>
          <p>{intl.formatMessage({ id: 'vision_description' })}</p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="" />
        </div>
        <div className="content">
          <h2>{intl.formatMessage({ id: 'values_title' })}</h2>
          <p>{intl.formatMessage({ id: 'values_description' })}</p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>{intl.formatMessage({ id: 'services_title' })}</h2>
          <p>
            {intl.formatMessage({ id: 'services_description_1' })} <br />
            {intl.formatMessage({ id: 'services_description_2' })}
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-mobile">
            <h3>{intl.formatMessage({ id: 'app_title' })}</h3>
            <p>{intl.formatMessage({ id: 'app_description' })}</p>
          </li>
          <li className="icon solid fa-laptop">
            <h3>{intl.formatMessage({ id: 'web_title' })}</h3>
            <p>{intl.formatMessage({ id: 'web_description' })}</p>
          </li>
          <li className="icon solid fa-rocket">
            <h3>{intl.formatMessage({ id: 'startup_title' })}</h3>
            <p>{intl.formatMessage({ id: 'startup_description' })}</p>
          </li>
          <li className="icon solid fa-code">
            <h3>{intl.formatMessage({ id: 'consulting_title' })}</h3>
            <p>{intl.formatMessage({ id: 'consulting_description' })}</p>
          </li>
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>{intl.formatMessage({ id: 'contact_us_title' })}</h2>
          <p>{intl.formatMessage({ id: 'contact_us_description' })}</p>
        </header>
        <ul className="actions stacked">
          <li>
            <OutboundLink
              href={intl.formatMessage({ id: 'form_url' })}
              target="_blank"
              rel="noreferrer"
              className="button fit primary"
            >
              {intl.formatMessage({ id: 'hire_button' })}
            </OutboundLink>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default injectIntl(IndexPage);
